function ws_AdaptForm (id, method, action){
	
	switch (method){
		case "POST":  $(id).attr("action", action);
					  $(id).attr("method", "POST");
					  $("input[name=_method]").val("POST");
					  break;
		case "PUT":	  $(id).attr("action", action);
					  $(id).attr("method", "POST"); 
					  $("input[name=_method]").val("PUT");
					  break;
		case "DELETE":$(id).attr("action", action);
					  $(id).attr("method", "POST"); 
					  $("input[name=_method]").val("DELETE");		
	}
}

function clearElements (elems){
	for (i = 0; i < elems.length; i++)
	{
		field_type = elems[i].type.toLowerCase();
		switch (field_type)
		{
			case "text":
				if (elems[i].name.substr(-3,3) != "rpp")
					elems[i].value = "";
				break;				
			case "password":
			case "textarea":
			case "hidden":
				if (elems[i].name != "_token")
					elems[i].value = "";
				break;
			case "radio":
			case "checkbox":
				if (elems[i].checked)
				{
					elems[i].checked = false;
				}
				break;
			case "select-one":
			case "select-multi":
				elems[i].selectedIndex = 0;
				break;
			default:
				break;
		}
	}
}

function clearForm (oForm){

	var frm_elements = oForm.elements;
	clearElements (frm_elements);
}

function clearClass (className){
	var elements = $("." + className);
	clearElements (elements);
}

function clearId (id){
	var elements = $("#" + id);
	clearElements (elements);
}

/*Format price as x.xx*/
function frmt(price){
	return (Math.round(price*100)/100).toFixed(2);
}

function create_query_string (elems)
{
	var res_str = "";
	
	for (i = 0; i < elems.length; i++)
	{	
		if (i > 0) 
			res_str = res_str + "&";
		
		field_type = elems[i].type.toLowerCase();
		switch (field_type)
		{
			case "text":
			case "password":
			case "textarea":
			case "hidden":
			case "select-one":
			case "select-multi":
				res_str = res_str + elems[i].name + "=" + encodeURIComponent(elems[i].value);
				break;
			case "radio":
			case "checkbox":
				if (elems[i].checked)
					res_str = res_str + elems[i].name + "=true";
				else
					res_str = res_str + elems[i].name + "=false";
			default:
				break;
		}		
		
	}
	return res_str;
}

$.extend({
	getUrlVars: function(){
		  
	var vars = {}, hash;
	var pos = window.location.href.indexOf('?');
	
	if (pos < 0)
		return {};
	
	var uri = window.location.href.replace (/\+/g, ' ');
	var hashes = uri.slice(pos + 1).split('&');
	for(var i = 0; i < hashes.length; i++)
	{
		hash = hashes[i].split('=');
	    vars[hash[0]] = decodeURIComponent(hash[1]);
	}
	return vars;
	},
	getUrlVar: function(name){
		return $.getUrlVars()[name];
	}
});


function is_similar(text1,text2){
	text1 = ""+text1+"" || "";
	text2 = ""+text2+"" || "";
	
	if (text1.length > text2.length){
		return text1.toUpperCase().indexOf(text2.toUpperCase())>=0;
	}else{
		return text2.toUpperCase().indexOf(text1.toUpperCase())>=0;
	}
}

function is_dateSimilar(text1,text2){
	text1 = text1 || "";
	text2 = text2 || "";
	
	var d1 = new Date(text1);
	var d2 = new Date(text2);

	return d1.toUTCString()==d2.toUTCString();
}

function setCookie(key, value) {
	var expires = new Date();
	expires.setTime(expires.getTime() + (1 * 24 * 60 * 60 * 1000));
	document.cookie = key + '=' + value +'; path=/'+ '; expires=' + expires.toUTCString();
}

function getCookie(key) {
	var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
	return keyValue ? keyValue[2] : null;
}

function reload(){
	document.location.href= document.location.href;
}

function showPopup (title, body)
{
	$("#modalWindowTitle").html(title);
	$("#modalWindowBody").html(body);
	$("#modalWindow").modal('show');

}